.pupildashboard{
	display: flex;
	flex-direction: row;
	background-color: var(--white-faded);
	justify-content: space-between;
	max-height: 80vh;
	min-height: 80vh
}

.assignedOrders{
	position: fixed;
	top: 15vh;
	left:38vw;
	width: 22vw;
	padding: 1vw;
	background-color: #eef;
	box-shadow:  0px 0px 3px 3px rgba(0,0,0,0.1);
	z-index: 10;
	display: flex;
	justify-content: center;
}

.pupildashboard .main{
	display: flex;
	flex-direction: column;
	background-color: white;
	box-shadow: 0 0 3px 3px rgba(150,150,150,0.3);
	padding: 1em;
	justify-content: flex-start;
	align-items: center;
	min-width: 300px;
	max-height: 70vh;
}


.pupildashboard .main .title{
	font-weight: bold;
	font-size: 1.1em;
}

#pendingOrders{
    min-width: 30vw;
		display: flex;
		background-color: transparent;
		box-shadow: none;
		min-width: 20em;
}

#pending-orders-div{
	height: 90vh;
	overflow-y: auto;
	min-width: 25em;
}
