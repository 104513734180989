.teacher-carrousel.box{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: var(--white);
    box-shadow: 0px 0px 1px grey;
    padding: 0.5em;
    position: fixed;
    z-index: 5;
    top: 15vh;
    width: 50vw;
    height: 70vh;
    left: 25vw;
    align-items: center;
}

.teacher-carrousel .carrousel{
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding: 0.5em;
}
