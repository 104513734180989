.frame{
	flex: 1;
	background-color: white;
	padding: 1em;
	box-shadow: 0px 0px 2px 2px rgba(0,0,0,0.1);
	font-size: 0.9em;
	margin: 0.2em;
	height: 67vh;
	overflow-y: auto;
}

.rater-indication{
	font-size: 0.9em;
	font-style: italic;
}

.rater-small{
	font-size: 0.8em;
	font-style: italic;
	text-align: center;
}

.rater-criteria{
	display: inline;
}

.rater-title{
	text-align: center;
	font-size: 1.5em;
}

.rater .criteria{
	display: flex;
	flex-direction: row;
}

.criteria .title{
	display: flex;
	flex-direction: column;
	flex: 2;
	justify-content: center;
	text-align: left;
}

.title .main{
	font-size: 1.1em;
	margin: 0px;
}

.title .secondary{
	color: #666;
	margin: 0px;
	margin-bottom: 1em;
	font-size: 0.7em;
}

.criteria .rate{
	display: flex;
	flex-direction: column;
	flex: 3;
}

