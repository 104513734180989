
/* Tooltip text */
#preferredTeacher-button .orderer-tooltip {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
#preferredTeacher-button:hover .orderer-tooltip {
  visibility: visible;
}
