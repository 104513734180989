.btn{
	border-radius: 50px;
	margin: 0.3em;
	border-style: none;
	padding: 0.5em;
	cursor: pointer;
	appearance: none;
	text-align: center;
	text-decoration: none;
	text-align-last: center;
}

.btn:hover{
	opacity: 0.7;
}

.btn.lg{
	font-size: 1.2em;
	padding: 0.5em;
}

.btn.selected{
	background-color: var(--validated);
}

.btn.link{
	text-decoration: none;
	background-color: rgba(0,0,0,0);
}

.btn.link.red{
	color: red;
	background-color: transparent;
}

.btn.link.green{
	color: var(--validated);
	background-color: transparent;
}

.btn.link.blue{
	color: var(--blue-btn);
	background-color: transparent;
}

.btn.link.white{
	color: var(--white);
	background-color: transparent;
}

.btn.link.yellow{
	color: var(--yellow);
	background-color: transparent;
}

.btn.blue{
	background-color: var(--blue-btn);
	color: white;
}

.btn.green{
	background-color: var(--validated);
	color: white;
}

.btn.white{
	background-color: white;
	color: black;
}

.btn.transparent{
	background-color: rgba(0,0,0,0);
	color: white;
}

.btn.yellow{
	background-color: var(--yellow);
	color: white;
}

.yellow.outline{
	background-color: var(--white);
	border: solid 3px var(--yellow);
	color: var(--black);
}

.red.outline{
	background-color: var(--white) !important;
	border: solid 3px var(--error);
	color: var(--black) !important;
}


.btn.red{
	background-color: var(--error);
	color: white;
}

.btn-blue-pyth{
	background-color: var(--blue-pyth);
}

.btn.sm{
	font-size: 0.8em;
}

.btn.sm p{
	margin: 0.4em;
}


.btn-group{
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
}

.btn-group div{
	margin: 0px;
	padding-top: 0px;
	padding-bottom: 0px;
	flex: 1;
}

.half-btn{
	background-color: white;
	flex: 1;
}

.square{
	border-radius: 0px;
}

.toclose{
	align-self: flex-end;
	padding:1em;
	padding-bottom: 0em;
	margin-top: -2em;
}

.toclose:hover{
	color: red !important;
}

.expandArea{
	font-size: 1.5em;
	align-self: flex-end;
	padding:0em;
	padding-bottom: 0em;
	margin-top: -1.4em;
	margin-right: -0.3em;
	margin-bottom: -2.3em;
	transform: rotateZ(90deg);
	transition: 0.2s;
}

.expandArea:hover{
	color: green !important;
}

.shrinkArea{
	position: absolute;
	top: -4.5vh;
	left: 90.4vw;
	font-size: 1.5em;
	align-self: flex-end;
	padding:0em;

	transform: rotateZ(-90deg);
	transition: 0.2s;
}

.shrinkArea:hover{
	color: green !important;
}

.btn.disabled{
	opacity: 0.2;
	cursor: not-allowed;
}

.btn.bold{
	font-weight: bold;
}

.btn.link.shadow{
	text-shadow: 0px 0px 2px rgba(80,80,80, 1);
}

.btn.rounded{
	border-radius: 5px;
}

.invalid{
	box-shadow: 0px 0px 1px 2px var(--error);
}
