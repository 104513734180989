.addaddress{
	max-width: 100%;
	box-shadow: 0px 0px 1px 0px rgba(0,0,0,0.2);
	overflow: hidden;
	padding: 0.5em;
	margin: 1em;
}

.addaddress #title{
	font-weight: bold;
	color: #555;
	text-align: center;
}

.addaddress #number{
	max-width: 20%;
}

.addaddress #street{
	max-width: 70%;
}

.addaddress #precision{
	width: 85%;
}

.addaddress #zipcode{
	max-width: 20%;
}
.addaddress #city{
	max-width: 70%;
}

.addaddress .btn{
	align-self: right;
}
