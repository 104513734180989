

.home-logo{
	height: 15em;
	width: 15em;
	margin-bottom: -2em;
	/*margin-top: -8em;*/
}

#homeButton{
	background-color: var(--blue-btn);
	padding: 0.8em;
	color: white;
	font-size: 1.1em;
	margin-top: 2em;
	width: 10em;
	margin-right: auto;
	margin-left: auto;

}
.form-container{
	/*background: radial-gradient(ellipse at center, var(--dark-blue) 40%, transparent 50%);*/

	/*height: 100vh;*/
}


.contact{
	position: absolute;
	top: 0px;
	left: 0px;
}


.login-footer-resetpwd{
	position: fixed;
	bottom: 0px;
	margin: 1px;
	display: flex;
	font-size: 0.7em;
	color: var(--gray);
}
