.visio-header{
	height: 3vh;
	width: 100vw;
	background-color: var(--blue);
	display: flex;
	align-items: center;
	color: var(--white);
}

.visio{
	display: flex;
	flex: 1;
	height: 97vh;
	overflow: hidden;


}

button .button {
	outline: none;
}

.button:hover {
	cursor: pointer;
}

.visio input{
	border-radius: 50px;
	float: left;
	border-style: none;
	padding: 0.5em;
	margin: 0.3em;
	text-align: left;
	flex: 1;
	outline: none;
}

.visio .input-line{
	display: flex;
	align-items: center; 
	position: relative;
	flex: 1;
	width: 100%;
}

.visio .input-panel{
	position: fixed;
	bottom: 0;
	height: 15vh;
	flex: 1;
	width: 20%;
}

.input-file {
    display: none;
}

.visio video{
	flex: 1;
}

#messagesList{
	background-color: rgba(245, 245, 250, 0.8);
}

.video-container{
	display: flex;
}

#background-logo{
}

.visio #files-modal{
	position: fixed;
	top: 20vh;
	left:40vw;
	width: 30vw;
	background-color: var(--white);
	z-index: 100;
	padding: 0.5em;
	border-radius: 10px;
	max-height: 70vh;
	overflow-y: auto;
}

.visio #backVideo{
	transform: scaleX(-1);
}
