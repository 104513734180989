.order-resume{
	position: absolute;
	top: 20vh;


	background-color: white;
	border-radius: 10px;
	padding: 5vw;
	padding-top: 1em;
	padding-bottom: 1em;
	box-shadow: 0px 0px 3px 3px rgba(200,200,200,0.5);

	display: flex;
	flex-direction: column;
	align-items: center;

	z-index: 10000;

	color: #444;
	max-height: 70vh;
}

#order-resume-title{
	font-size: 1.1em;
}


#order-resume-body{

	border-radius: 10px;
	padding: 3vw;
	box-shadow:inset 0px 0px 3px 1px rgba(200,200,200,0.5);
	margin-bottom: 0.5em;
	display: flex;
	flex-wrap: wrap;
}

#order-resume-body .column{
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
}

#order-resume-body .column.first{
	padding-right: 0.5vw;
}

#order-resume-body .column.secondary{
	border-left: solid var(--gray) 2px;
	padding-left: 0.5vw
}

.order-resume-line{
	display: flex;
	margin-bottom: -0.8em;
	margin-top: -0.8em;
}

.order-resume-item{
	font-weight: bold;
	overflow-wrap: break-word;
	max-width: 15em;
}

#order-resume-warning{
    font-style: italic;
    color: var(--blue);
	margin: 5px 5px 15px;
	font-size: 1.1em;
}

.order-resume #precisions{
	width: 100%;
}

#orderer-btn-back {
	position: absolute;
	top: 15px;
	left: 15px;
}

.orderer-error {
	color: red;
}

#precisions {

}
