.courses.container{
	/*position: fixed;
	top: 9.5em;
	left: 18.5em;*/
	width: 20em;
	background-color: var(--background);

	border-right-style: solid;
	border-right-color: var(--blue);
	overflow-x: hidden;
	padding-bottom: 0.3em;
	/*transition: left 2s ease 0s, height 2s, width 2s;*/
}

.courses.expanded{
	display: flex;
	flex-direction: row;
	background-color: var(--background);
	height: 80vh; /*12+77+1*/
	width: 100vw;
	position: fixed;
	top: 10vh;
	left: 0vw;
	/*transition: position 2s, height 2s, width 2s;*/
	padding-left: 0.5em;
	padding-bottom: 1vh;
	z-index: 8;
	overflow-x: auto;
	min-width: 400px;
}

@media all and (max-width: 400px){
	.courses.expanded{
		flex-direction: column;

	}

	.courses .scroll-y.list{
		height: 20vh !important;
		width: 100vw !important;
		display: flex;
		flex-direction: row !important;
	}

	.courses .scroll-y.list div{
		margin-right: 2vw;
	}

	.big-courses.details{
		flex-direction: column;
	}

	.courses .details{
		padding: 0 !important;
		padding-top: 1vh !important;
		background-color: white;
		flex: 1;
		text-align: center;
		align-items: center;
		align-content: flex-start;
		margin-right: 10vw !important;
	}

	.big-courses.details .infos{
		box-shadow: none !important;
		padding: 2vw !important;
		margin-top: 0vh !important;
		width: 80vw !important;
	}

	.big-courses.details .specific{
		padding: 1vw;
		margin-top: -3px;

	}
}

.courses .title{
	padding: 0px;
	margin: 3px;
	margin-left: 1em;
	margin-bottom: -0.4em;
	font-size: 1.3em;
	font-weight: bold;
	color: var(--gray);
	align-self: flex-start;
}

.courses .details{
	padding: 1em;
	/*padding-top: 2em;*/
	background-color: white;
	flex: 1;
	text-align: center;
	align-items: center;
	align-content: flex-start;
	margin:0.5em;
	margin-right: 1em;
}

.courses.legend{
	font-size: 0.85em;
	display: flex;
	flex-direction: row;
	padding: 0.2em;
	padding-top: 0.5em;
}

.courses.legend.item{
	appearance: none;
	border: none;
	background-color: rgba(0,0,0,0);
	cursor: pointer;
}

.courses.legend.item.blue{
  color: var(--blue);
  margin-left: 0.3em;
  margin-right: 0.1em;
}

.courses.legend.item.red{
  color: var(--error);
  margin-left: 0.3em;
  margin-right: 0.1em;
}

.courses.legend.item.yellow{
  color: var(--yellow);
  margin-left: 0.3em;
  margin-right: 0.1em;
}

.courses.legend.item.green{
  color: var(--green);
  margin-left: 0.3em;
  margin-right: 0.1em;
}

.courses .scroll-y.list{
	height: 72vh;
	width: 20em;
	display: flex;
	flex-direction: column;
	margin-top: 1em;
	/*justify-content: flex-start;*/
}

.courses .avatar{
	height: 20vh;
	width: 20vh;
	border-top-left-radius: 20% 50%;
	border-bottom-left-radius: 20% 50%;
	border-top-right-radius: 0%;
	border-bottom-right-radius: 0%;
	padding-top: -2em;
	margin-top: 0em;
	padding-bottom: -2em;
	margin-bottom: 0em;
	margin-left: 0.5em;
}

.interlocutorCard{
	display: flex;
	flex-direction: row-reverse;
	box-shadow: inset 0px 0px 2px 2px rgba(0,0,0,0.1);
	flex: 0;
	justify-content: space-between;
	padding: 0em;
	margin: 0.3em;
	padding-bottom: 0em;
	padding-left: 1em;
	width: 90%;
}

.interlocutorCard .text{
	display: flex;
	flex-direction: column;
	text-align: left;
	align-items: flex-start;
	margin-left: 2em;
}

.interlocutorCard .title{
	font-size: 0.9em;
	margin: 0;
	padding-top: 1em;
	margin-bottom: 0.5em;
	color: #555;
	text-align: left;
	margin-left: -2em;
}

.interlocutorCard .firstname{
	font-size: 1.2em;
	font-weight: bold;
	color: var(--dark-blue);
	padding: 0;
	margin: 0;
}

.interlocutorCard .lastname{
	padding: 0;
	margin: 0;
	font-size: 1.1em;
	color: var(--dark-blue);
}

.interlocutorCard .number{
	padding: 0;
	margin: 0;
	margin-bottom: 0em;
	font-size: 1em;
	color: #777;
}

.courses .rater{
	flex: 2;
}

.big-courses.details{
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	overflow-y: auto;
	justify-content: flex-start;
	align-items: flex-start;
	box-shadow: 0 0 2px 2px rgba(150,150,150,0.3);
}

.big-courses.details .infos{
	display: flex;
	flex: 1 1;
	align-items: flex-start;
	justify-content: flex-start;
	box-shadow: 0 0 2px 2px rgba(150,150,150,0.3);
	padding: 1em;
	/*margin-top: 1em;*/

}

.big-courses.details .specific{
	display: flex;
	flex-direction: column;
	align-items: center;
	flex: 1 1;
	padding: 1em;
	margin-top: 1em;
}

.big-courses.details .item{
	margin-bottom: 0.5em;
}

.big-courses.ordered{
	display: flex;
	flex-direction: column;
	flex: 1;
	align-items: center;
	justify-content: center;
	color: #999;
}

.big-courses .cancelled{
	display: flex;
	flex-direction: column;
	flex: 1;
	align-items: center;
	justify-content: center;
	color: var(--error);
}

.big-courses .confirmed{
	display: flex;
	flex-direction: column;
	flex: 1;
	align-items: center;
	justify-content: center;
	color: var(--error);
}

.big-courses .done{
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	flex: 1;
	align-items: center;
	justify-content: center;
	color: var(--validated);
}

#bg-today-cursor, #sm-today-cursor{
	color: var(--blue);
	font-size: 0.9em;
	width: 90%;
	/*background-color: var(--blue);*/
	border-bottom: 2px solid var(--blue);
	text-align: center;
	padding: 2px;
}
