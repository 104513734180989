.smalluserdata.container{
	width: 18.4em;
	height: 100%;
	display: flex;
	background-color: white;
	padding: 0.8em;
	padding-top: 0em;
	align-items: flex-end;
	border-left-style: solid;
	border-left-color: var(--blue);
	text-align: right;
}

.smalluserdata .title{
	padding: 0px;
	margin: 3px;
	margin-right: 1em;
	margin-bottom: -0.4em;
	font-size: 1.3em;
	font-weight: bold;
	color: var(--gray);
	align-self: flex-end;
}

.smalluserdata .row.space-between{
	width: 18.4em;
	align-items: flex-start;
}

.smalluserdata .textualElements{
	flex: 1;
	align-items: flex-end;
}

.smalluserdata .avatar {
	flex: 1;
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;

	margin-right: 2em;
}

.smalluserdata .avatar .small{
	height: 90%;
	width: 90%;
	border-radius: 50%;
}

.smalluserdata .item{
	font-size: 1.1em;
	color: #444;
	max-height: 40vh;
}

.smalluserdata .item.topics{
	margin-left: 0.5em;
	margin-bottom: -1em;
	overflow: hidden;

}

.smalluserdata .item .topic{
	margin: 0em;
}

.smalluserdata .addresses{
	max-height: 17vh;
	overflow: hidden;
}

.smalluserdata  p{
	margin: 0.4em;
}

.smalluserdata hr{
	color: var(--blue);
	opacity: 0.2;
	margin-top: -0.2em;
}
