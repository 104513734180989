#article_pepite .container {
    -webkit-print-color-adjust: exact;
    width: 100%;
    background-color: white;
    display: flex;
    flex-direction: colonne;
    justify-content: center;
}
#article_pepite * {
    box-sizing: border-box;
    -webkit-print-color-adjust: exact;
    color: #223;
}

#article_pepite
div, body {
    margin: 0;
    padding: 0;
}
#article_pepite @media only screen {
    #article_pepite body {
        margin: 2em auto;
        max-width: 900px;
        color: rgb(55, 53, 47);
    }
}

#article_pepite .page-body {
    padding-bottom: 30vh;
    padding-top: 5vh;
    line-height: 1.5;
    white-space: pre-wrap;
    width: 80vw;
    margin-left: 10vw;
    margin-right: 10vw;
    padding-left: 10vw;
    padding-right: 10vw;
    background-color: white;
}

#article_pepite a, a.visited {
    color: inherit;
    text-decoration: underline;
}

#article_pepite .pdf-relative-link-path {
    font-size: 80%;
    color: #444;
}
#article_pepite
h1,
h2,
 h3 {
    letter-spacing: -0.01em;
    line-height: 1.2;
    font-weight: 600;
    margin-bottom: 0;
}

#article_pepite .page-title {
    font-size: 2.5rem;
    font-weight: 700;
    margin-top: 0;
    margin-bottom: 0.75em;
}

#article_pepite h1 {
    font-size: 1.875rem;
    margin-top: 1.875rem;
}

#article_pepite h2 {
    font-size: 1.5rem;
    margin-top: 1.5rem;
}

#article_pepite h3 {
    font-size: 1.25rem;
    margin-top: 1.25rem;
}

#article_pepite .source {
    border: 1px solid #ddd;
    border-radius: 3px;
    padding: 1.5em;
    word-break: break-all;
}

#article_pepite .callout {
    border-radius: 3px;
    padding: 1rem;
}

#article_pepite figure {
    margin: 1.25em 0;
    page-break-inside: avoid;
}

#article_pepite figcaption {
    opacity: 0.5;
    font-size: 85%;
    margin-top: 0.5em;
}

#article_pepite mark {
    background-color: transparent;
}

#article_pepite .indented {
    padding-left: 1.5em;
}

#article_pepite hr {
    background: transparent;
    display: block;
    width: 100%;
    height: 1px;
    visibility: visible;
    border: none;
    border-bottom: 1px solid rgba(55, 53, 47, 0.09);
}

#article_pepite img {
    max-width: 100%;
}

#article_pepite @media only print {
    #article_pepite img {
        max-height: 100vh;
        object-fit: contain;
    }
}

#article_pepite @page {
    margin: 1in;
}

#article_pepite .collection-content {
    font-size: 0.875rem;
}

#article_pepite .colonne-list {
    display: flex;
    justify-content: space-between;
}

#article_pepite .colonne {
    padding: 0 1em;
}

#article_pepite .table_of_contents-item {
    display: block;
    font-size: 0.875rem;
    line-height: 1.3;
    padding: 0.125rem;
}

#article_pepite .table_of_contents-indent-1 {
    margin-left: 1.5rem;
}

#article_pepite .table_of_contents-indent-2 {
    margin-left: 3rem;
}

#article_pepite .table_of_contents-indent-3 {
    margin-left: 4.5rem;
}

#article_pepite .table_of_contents-link {
    text-decoration: none;
    opacity: 0.7;
    border-bottom: 1px solid rgba(55, 53, 47, 0.18);
}

#article_pepite
table,
th,
 td {
    border: 1px solid rgba(55, 53, 47, 0.09);
    border-collapse: collapse;
}

#article_pepite table {
    border-left: none;
    border-right: none;
}

th,
#article_pepite td {
    font-weight: normal;
    padding: 0.25em 0.5em;
    line-height: 1.5;
    min-height: 1.5em;
    text-align: left;
}

#article_pepite th {
    color: rgba(55, 53, 47, 0.6);
}

ol,
#article_pepite ul {
    margin: 0;
    margin-block-start: 0.6em;
    margin-block-end: 0.6em;
}

li > ol:first-child,
#article_pepite li > ul:first-child {
    margin-block-start: 0.6em;
}

#article_pepite ul > li {
    list-style: disc;
}

#article_pepite ul.to-do-list {
    text-indent: -1.7em;
}

#article_pepite ul.to-do-list > li {
    list-style: none;
}

#article_pepite .to-do-children-checked {
    text-decoration: line-through;
    opacity: 0.375;
}

#article_pepite ul.toggle > li {
    list-style: none;
}

#article_pepite ul {
    padding-inline-start: 1.7em;
}

#article_pepite ul > li {
    padding-left: 0.1em;
}

#article_pepite ol {
    padding-inline-start: 1.6em;
}

#article_pepite ol > li {
    padding-left: 0.2em;
}

#article_pepite .mono ol {
    padding-inline-start: 2em;
}

#article_pepite .mono ol > li {
    text-indent: -0.4em;
}

#article_pepite .toggle {
    padding-inline-start: 0em;
    list-style-type: none;
}

/* Indent toggle children */
#article_pepite .toggle > li > details {
    padding-left: 1.7em;
}

#article_pepite .toggle > li > details > summary {
    margin-left: -1.1em;
}

#article_pepite .selected-value {
    display: inline-block;
    padding: 0 0.5em;
    background: rgba(206, 205, 202, 0.5);
    border-radius: 3px;
    margin-right: 0.5em;
    margin-top: 0.3em;
    margin-bottom: 0.3em;
    white-space: nowrap;
}

#article_pepite .collection-title {
    display: inline-block;
    margin-right: 1em;
}

#article_pepite time {
    opacity: 0.5;
}

#article_pepite .icon {
    display: inline-block;
    max-width: 1.2em;
    max-height: 1.2em;
    text-decoration: none;
    vertical-align: text-bottom;
    margin-right: 0.5em;
}

#article_pepite img.icon {
    border-radius: 3px;
}

#article_pepite .user-icon {
    width: 1.5em;
    height: 1.5em;
    border-radius: 100%;
    margin-right: 0.5rem;
}

#article_pepite .user-icon-inner {
    font-size: 0.8em;
}

#article_pepite .text-icon {
    border: 1px solid #000;
    text-align: center;
}

#article_pepite .page-cover-image {
    display: block;
    object-fit: cover;
    width: 100%;
    height: 30vh;
}

#article_pepite .page-header-icon {
    font-size: 3rem;
    margin-bottom: 1rem;
}

#article_pepite .page-header-icon-with-cover {
    margin-top: -0.72em;
    margin-left: 0.07em;
}

#article_pepite .page-header-icon img {
    border-radius: 3px;
}

#article_pepite .link-to-page {
    margin: 1em 0;
    padding: 0;
    border: none;
    font-weight: 500;
}

#article_pepite p > .user {
    opacity: 0.5;
}

td > .user,
#article_pepite td > time {
    white-space: nowrap;
}

#article_pepite input[type="checkbox"] {
    transform: scale(1.5);
    margin-right: 0.6em;
    vertical-align: middle;
}

#article_pepite p {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
}

#article_pepite .image {
    border: none;
    margin: 1.5em 0;
    padding: 0;
    border-radius: 0;
    text-align: center;
}

.code,
#article_pepite code {
    background: rgba(135, 131, 120, 0.15);
    border-radius: 3px;
    padding: 0.2em 0.4em;
    border-radius: 3px;
    font-size: 85%;
    tab-size: 2;
}

#article_pepite code {
    color: #eb5757;
}

#article_pepite .code {
    padding: 1.5em 1em;
}

#article_pepite .code-wrap {
    white-space: pre-wrap;
    word-break: break-all;
}

#article_pepite .code > code {
    background: none;
    padding: 0;
    font-size: 100%;
    color: inherit;
}

#article_pepite blockquote {
    font-size: 1.25em;
    margin: 1em 0;
    padding-left: 1em;
    border-left: 3px solid rgb(55, 53, 47);
}

#article_pepite .bookmark {
    text-decoration: none;
    max-height: 8em;
    padding: 0;
    display: flex;
    width: 100%;
    align-items: stretch;
}

#article_pepite .bookmark-title {
    font-size: 0.85em;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 1.75em;
    white-space: nowrap;
}

#article_pepite .bookmark-text {
    display: flex;
    flex-direction: colonne;
}

#article_pepite .bookmark-info {
    flex: 4 1 180px;
    padding: 12px 14px 14px;
    display: flex;
    flex-direction: colonne;
    justify-content: space-between;
}

#article_pepite .bookmark-image {
    width: 33%;
    flex: 1 1 180px;
    display: block;
    position: relative;
    object-fit: cover;
    border-radius: 1px;
}

#article_pepite .bookmark-description {
    color: rgba(55, 53, 47, 0.6);
    font-size: 0.75em;
    overflow: hidden;
    max-height: 4.5em;
    word-break: break-word;
}

#article_pepite .bookmark-href {
    font-size: 0.75em;
    margin-top: 0.25em;
}

#article_pepite .sans { font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, "Apple Color Emoji", Arial, sans-serif, "Segoe UI Emoji", "Segoe UI Symbol"; }
#article_pepite .code { font-family: "SFMono-Regular", Consolas, "Liberation Mono", Menlo, Courier, monospace; }
#article_pepite .serif { font-family: Lyon-Text, Georgia, YuMincho, "Yu Mincho", "Hiragino Mincho ProN", "Hiragino Mincho Pro", "Songti TC", "Songti SC", "SimSun", "Nanum Myeongjo", NanumMyeongjo, Batang, serif; }
#article_pepite .mono { font-family: iawriter-mono, Nitti, Menlo, Courier, monospace; }
#article_pepite .pdf .sans { font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, "Apple Color Emoji", Arial, sans-serif, "Segoe UI Emoji", "Segoe UI Symbol", 'Twemoji', 'Noto Color Emoji', 'Noto Sans CJK SC', 'Noto Sans CJK KR'; }

#article_pepite .pdf .code { font-family: Source Code Pro, "SFMono-Regular", Consolas, "Liberation Mono", Menlo, Courier, monospace, 'Twemoji', 'Noto Color Emoji', 'Noto Sans Mono CJK SC', 'Noto Sans Mono CJK KR'; }

#article_pepite .pdf .serif { font-family: PT Serif, Lyon-Text, Georgia, YuMincho, "Yu Mincho", "Hiragino Mincho ProN", "Hiragino Mincho Pro", "Songti TC", "Songti SC", "SimSun", "Nanum Myeongjo", NanumMyeongjo, Batang, serif, 'Twemoji', 'Noto Color Emoji', 'Noto Sans CJK SC', 'Noto Sans CJK KR'; }

#article_pepite .pdf .mono { font-family: PT Mono, iawriter-mono, Nitti, Menlo, Courier, monospace, 'Twemoji', 'Noto Color Emoji', 'Noto Sans Mono CJK SC', 'Noto Sans Mono CJK KR'; }

#article_pepite .highlight-default {
}
#article_pepite .highlight-gray {
    color: rgb(155,154,151);
}
#article_pepite .highlight-brown {
    color: rgb(100,71,58);
}
#article_pepite .highlight-orange {
    color: rgb(217,115,13);
}
#article_pepite .highlight-yellow {
    color: rgb(223,171,1);
}
#article_pepite .highlight-teal {
    color: rgb(15,123,108);
}
#article_pepite .highlight-blue {
    color: rgb(11,110,153);
}
#article_pepite .highlight-purple {
    color: rgb(105,64,165);
}
#article_pepite .highlight-pink {
    color: rgb(173,26,114);
}
#article_pepite .highlight-red {
    color: rgb(224,62,62);
}
#article_pepite .highlight-gray_background {
    background: rgb(235,236,237);
}
#article_pepite .highlight-brown_background {
    background: rgb(233,229,227);
}
#article_pepite .highlight-orange_background {
    background: rgb(250,235,221);
}
#article_pepite .highlight-yellow_background {
    background: rgb(251,243,219);
}
#article_pepite .highlight-teal_background {
    background: rgb(221,237,234);
}
#article_pepite .highlight-blue_background {
    background: rgb(221,235,241);
}
#article_pepite .highlight-purple_background {
    background: rgb(234,228,242);
}
#article_pepite .highlight-pink_background {
    background: rgb(244,223,235);
}
#article_pepite .highlight-red_background {
    background: rgb(251,228,228);
}
#article_pepite .block-color-default {
    color: inherit;
    fill: inherit;
}
#article_pepite .block-color-gray {
    color: rgba(55, 53, 47, 0.6);
    fill: rgba(55, 53, 47, 0.6);
}
#article_pepite .block-color-brown {
    color: rgb(100,71,58);
    fill: rgb(100,71,58);
}
#article_pepite .block-color-orange {
    color: rgb(217,115,13);
    fill: rgb(217,115,13);
}
#article_pepite .block-color-yellow {
    color: rgb(223,171,1);
    fill: rgb(223,171,1);
}
#article_pepite .block-color-teal {
    color: rgb(15,123,108);
    fill: rgb(15,123,108);
}
#article_pepite .block-color-blue {
    color: rgb(11,110,153);
    fill: rgb(11,110,153);
}
#article_pepite .block-color-purple {
    color: rgb(105,64,165);
    fill: rgb(105,64,165);
}
#article_pepite .block-color-pink {
    color: rgb(173,26,114);
    fill: rgb(173,26,114);
}
#article_pepite .block-color-red {
    color: rgb(224,62,62);
    fill: rgb(224,62,62);
}
#article_pepite .block-color-gray_background {
    background: rgb(235,236,237);
}
#article_pepite .block-color-brown_background {
    background: rgb(233,229,227);
}
#article_pepite .block-color-orange_background {
    background: rgb(250,235,221);
}
#article_pepite .block-color-yellow_background {
    background: rgb(251,243,219);
}
#article_pepite .block-color-teal_background {
    background: rgb(221,237,234);
}
#article_pepite .block-color-blue_background {
    background: rgb(221,235,241);
}
#article_pepite .block-color-purple_background {
    background: rgb(234,228,242);
}
#article_pepite .block-color-pink_background {
    background: rgb(244,223,235);
}
#article_pepite .block-color-red_background {
    background: rgb(251,228,228);
}
#article_pepite .select-value-color-default { background-color: rgba(206,205,202,0.5); }
#article_pepite .select-value-color-gray { background-color: rgba(155,154,151, 0.4); }
#article_pepite .select-value-color-brown { background-color: rgba(140,46,0,0.2); }
#article_pepite .select-value-color-orange { background-color: rgba(245,93,0,0.2); }
#article_pepite .select-value-color-yellow { background-color: rgba(233,168,0,0.2); }
#article_pepite .select-value-color-green { background-color: rgba(0,135,107,0.2); }
#article_pepite .select-value-color-blue { background-color: rgba(0,120,223,0.2); }
#article_pepite .select-value-color-purple { background-color: rgba(103,36,222,0.2); }
#article_pepite .select-value-color-pink { background-color: rgba(221,0,129,0.2); }
#article_pepite .select-value-color-red { background-color: rgba(255,0,26,0.2); }

#article_pepite .checkbox {
    display: inline-flex;
    vertical-align: text-bottom;
    width: 16;
    height: 16;
    background-size: 16px;
    margin-left: 2px;
    margin-right: 5px;
}

#article_pepite .checkbox-on {
    background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Crect%20width%3D%2216%22%20height%3D%2216%22%20fill%3D%22%2358A9D7%22%2F%3E%0A%3Cpath%20d%3D%22M6.71429%2012.2852L14%204.9995L12.7143%203.71436L6.71429%209.71378L3.28571%206.2831L2%207.57092L6.71429%2012.2852Z%22%20fill%3D%22white%22%2F%3E%0A%3C%2Fsvg%3E");
}

#article_pepite .checkbox-off {
    background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Crect%20x%3D%220.75%22%20y%3D%220.75%22%20width%3D%2214.5%22%20height%3D%2214.5%22%20fill%3D%22white%22%20stroke%3D%22%2336352F%22%20stroke-width%3D%221.5%22%2F%3E%0A%3C%2Fsvg%3E");
}
