:root{
	--blue-pyth: #449ace;
	--dark-blue: #002d6d;
	--blue-btn: #0d4ed9;
	--blue: #4080ff;
	--background: #f5f5f7;
	--error: #e02d2d;
	--orange: #ff93dd;
	--yellow: #ffc700;
	--green: #35b773;
	--gray: #939393;
	--validated: #23d4ac;
	--white: #fff;
	--white-faded: rgba(255,255,255,0.8);
}

*:focus{
	outline: none;
}

.App{
	background-color: var(--background);
}
/*
p, select, div{
	text-transform: capitalize;
	text-transform: uppercase;
}*/

.frame-only{
	box-shadow: 0px 0px 1px 1px rgba(150,150,150,0.2);
	margin: 0.1em;
}

.bg-white{
	background-color: white;
}

.rounded{
	border-radius: 15px;
}

.scroll-y{
	overflow-y: auto;
	padding-right: 0em;
}

.hid-scroll{
	padding-right: 1em;
	margin-right: -2.2em;
}

.row{
	display: flex;
	flex-direction: row;
	justify-content: space-around;
}

.column{
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-around;
}

.flex-start{
	justify-content: flex-start;
}

.start{
    align-items: start;
}

.space-around{
	justify-content: space-around;
}

.center{
	justify-content: center;
}

.full-width{
	width: 100%;
}

.full-height{
	height: 100%;
}

.space-between{
	display: flex;
	justify-content: space-between;
}

.container{

}

.sand_glass{
	margin: 1em;
	height: 7vh;
	width: 7vh;
}

.sand_glass.reversed{
	transform: rotateZ(180deg);
}

.no-decoration{
    text-decoration: none;
}

.mirror{
	transform: scaleX(-1);
}

p.italic{
	font-style: italic;
}
