	.subs.container{
	overflow: hidden;
	width: 100vw;
	margin-left: -8px;
	padding-left: 0px;
}

.subs-title{
	text-align: center;
	font-size: 2em;
	padding: 3em;
	padding-top: 0.1em;
	padding-bottom: 0.1em;
	background-color:var(--blue-btn);
	color: white;
	border-radius: 5px;
}

.subs-description {
	width: 50%;
	margin-bottom: 1vh;
}

.subs-btns{
	display: flex;
	flex-direction: column;
}

.subs-btn{
	min-width: 20vw;
	padding-left: 4em !important;
	padding-right: 4em !important;
}

.subs-cards{
	display: flex;
	margin-top: 2em;
	margin-bottom: 2em;
	margin-right: 0vw;
	transition-duration: 0.4s;
}

.subs-cards.card1{
	display: flex;
	margin-top: 2em;
	margin-bottom: 2em;
	margin-right: -53vw;
	transition-duration: 0.4s;
}

.subs-cards.card2{
	display: flex;
	margin-top: 2em;
	margin-bottom: 2em;
	margin-right: -27vw;
	transition-duration: 0.4s;
}

.subs-cards.card3{
	display: flex;
	margin-top: 2em;
	margin-bottom: 2em;
	margin-right: 0vw;
	transition-duration: 0.4s;
}

.subs-cards.card4{
	display: flex;
	margin-top: 2em;
	margin-bottom: 2em;
	margin-right: 27vw;
	transition-duration: 0.4s;
}

.subs-cards.card5{
	display: flex;
	margin-top: 2em;
	margin-bottom: 2em;
	margin-right: 53vw;
	transition-duration: 0.4s;
}

.subs-card{
	display: flex;
	flex-direction: column;
	padding: 1em;
	margin: 0.5vw;
	max-width: 10vw;
	min-width: 10vw;
	background-color: white;
	box-shadow: 2px 2px 3px 3px rgba(0,0,0,0.1);
	margin-top: 0px;
	margin-bottom: 0px;
	transition-duration: .05s;
	cursor: pointer;
	border-radius: 5px;
}

.subs-card:hover{
	margin-top: -5px;
	margin-bottom: 5px;
	transition-duration: .1s;
	box-shadow: 2px 7px 3px 3px rgba(0,0,0,0.1);
}

.subs-card.active{
	border-color: var(--blue);/*#FFD700;*/
	background-color: rgba(255,255,255,1);

	margin-top: -10px;
	margin-bottom: 10px;
	transition-duration: .1s;
	box-shadow: 4px 12px 3px 3px rgba(0,0,0,0.1);
/*
	height: 7em;
	/*margin-right: 1.66%;
	margin-left: 1.66%;
	width: 33%;
	transition-duration: .05s;

	position: relative;
	top: -1em;*/
}

.subs-card.platine{
	background: radial-gradient(ellipse farthest-corner at right bottom, #FFF 0%, #dadada 8%, #BBB 30%, #CCC 40%, transparent 70%),
                radial-gradient(ellipse farthest-corner at left top, #FFFFFF 0%, #FFFFEa 8%, #DDD 25%, #FFE 62.5%, #fff 100%);
}

.gold{
	background: radial-gradient(ellipse farthest-corner at right bottom, #FEDB37 0%, #FDB931 8%, #9f7928 30%, #8A6E2F 40%, transparent 80%),
                radial-gradient(ellipse farthest-corner at left top, #FFFFFF 0%, #FFFFAC 8%, #D1B464 25%, #5d4a1f 62.5%, #5d4a1f 100%);
}

.subs-card.silver{
	background: radial-gradient(ellipse farthest-corner at right bottom, #EEE 0%, #c9c9c9 8%, #6a6a6a 30%, #555 40%, transparent 70%),
                radial-gradient(ellipse farthest-corner at left top, #FFFFFF 0%, #EED 8%, #CCC 25%, #555 62.5%, #555555 100%);
}

.subs-card.bronze{
	background: radial-gradient(ellipse farthest-corner at right bottom, #FFCB37 0%, #FFA931 8%, #cf7928 30%, #bA6E2F 40%, transparent 80%),
                radial-gradient(ellipse farthest-corner at left top, #FFFFFF 0%, #FFEEAC 8%, #EEB464 25%, #8d4a1f 62.5%, #8d4a1f 100%);
}

.subs-card.chocolate{
	background: radial-gradient(ellipse farthest-corner at right bottom, #cc4400 0%, #802b00 8%, #4d1a00 30%, #4d1a00 40%, transparent 80%),
                radial-gradient(ellipse farthest-corner at left top, #FFFFFF 0%, #FFEEAC 8%, #EEB464 25%, #8d4a1f 62.5%, #8d4a1f 100%);
}


.duration{
	font-weight: bold;
	margin: 0px;
}

.price{
	margin: 0px;
	margin-bottom: 5px;
	font-weight: bold;
}

.expiry{
	color: var(--gray);
}

.claimer{
	font-size: 0.5em;
}

.claimer.active{
	font-size: 0.5em;
}

.subs-invisible{
	display: none;
}

#back-button{
	float: right;
}
