.ordercard.container{
	display: flex;
	background-color: white;
	width: 18em;
	border-left: solid;
	margin-top: 0.5em;
	transition: background-color 0.5s;
	border-color: rgba(0,0,100,0.3);
}

.ordercard.container.no-buttons{
	cursor: pointer;
}

.ordercard.container.no-buttons:hover{
	background-color: #e7e7f7;
	transition: background-color 0s;
}

.ordercard.active{
	box-shadow: -10px 0px 3px 3px rgba(0,0,0,0.1);
	transform: scale(1.01) translate(0.3em, 0em);
	background-color: #f0f0ff;
}

.ordercard.blue{
	border-color: var(--blue);
	--bottom-color: var(--blue);
}

.ordercard.green{
	border-color: var(--green);
	--bottom-color: var(--green);
}

.ordercard.red{
	border-color: red;
	--bottom-color: red;
}

.ordercard.yellow{
	border-color: var(--yellow);
	--bottom-color: var(--yellow);
}

.ordercard.status{
	color: var(--bottom-color);
	font-size: 0.7em;
	padding-left: 1.5em;
	height: 0em;
	align-self: flex-start;
}

.ordercard.name{
	font-size: 0.7em;
	padding-left: 1.5em;
	height: 0em;
	align-self: flex-start;
}

.ordercard.body{
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	width: 100%;
	padding-bottom: 0.8em;
}

.ordercard.left{
	display: flex;
	flex: 1 0;
	flex-direction: column;
	justify-content: flex-start;
	padding-left: 1em;
	text-align: left;
}

.ordercard.right{
	display: flex;
	flex: 1 0;
	flex-direction: column;
	text-align: right;
	justify-content: flex-end;
	padding-right: 0.5em;
	max-width: 50%;
}

.ordercard.bottom{
	width: 19em;
	text-align: center;
	margin-bottom: -0.2em;
	margin-top: -0.2em;
	color: var(--bottom-color);
}

.ordercard.date{
	color: black;
	font-size: 1em;
	height: 0em;
	margin-bottom: 0.3em;
	margin-top: 0.3em;
}
.ordercard.level{
	color: var(--blue);
	font-size: 0.85em;
	height: 0em;
	margin-bottom: 0px;
}
.ordercard.price{
	color: var(--yellow);
	font-size: 1.4em;
	font-weight: bold;
	margin-top: 1em;
	height: 1em;
}
.ordercard.time{
	align-self: flex-end;
	color: black;
	font-size: 1em;
	margin-top: 0.3em;
	margin-bottom: -0.9em;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	max-width: 100%
}

.ordercard.topic{
	align-self: flex-end;
	color: #999;
	font-size: 0.85em;
	margin-bottom: -0.9em;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	max-width: 50%
}

.ordercard.address1{
	align-self: flex-end;
	color: var(--blue);
	font-size: 0.85em;
	padding-left: 0.1em;
	margin-bottom: 0.3em;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	max-width: 100%;
}
.ordercard.address2{
	align-self: flex-end;
	color: var(--blue);
	font-size: 0.85em;
	padding-left: 0.1em;
	margin-top: -0.3em;
	margin-bottom: 0.3em;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	width: 100%;
}

.ordercard.details{position: absolute;
	top: 0%;
	background-color: white;
	border-radius: 10px;
	padding: 0.3em;
	box-shadow: 0px 0px 3px 3px rgba(200,200,200,0.5);

	display: flex;
	flex-direction: column;
	align-items: center;
	width: 19em;
}

.ordercard.cancelled{
	color: red;
}

.ordercard.btn.detail{
	color:  var(--bottom-color);
}

.red-cross{
	font-size: 4em;
	margin: 0.2em;
	text-align: center;
	color: red;
}

.ordercard.btn.detail.opened{
	color:  var(--bottom-color);
	z-index: 10;
}

#big p{
	font-size: 1.2em !important;
}

.ordercard.huge{
	transform: scale(1.1);
	width: 70%;
	margin: auto;
	margin-top: 0em;
}

/*
adding margin for pending order list
*/
.ordercard.huge.teacherside{
	margin-top: 1.5em;
}
