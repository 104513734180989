@media (orientation: landscape) {
    .contact-and-legals {
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        background-color: rgba(0, 0, 0, 0) !important;
        text-decoration: none;
        z-index: 10;
    }
    .contact-and-legals .number.horizontal{
        opacity: 0.5;
        margin-left: -90px;
        margin-right: -90px;
        margin-bottom: -90px;
        position: absolute;
        top: -15vh;
        left: 20vw;
        padding: 5px;
        border-radius: 5px;
        background-color: var(--dark-blue);
        transition: 0.2s;
        font-size: 1.8em;
    	transition: 0.08s;
    }
    .contact-and-legals .number.vertical{
        opacity: 0.5;
        margin-right: -240px;
        margin-bottom: -80px;
        position: absolute;
        top: 117px;
        left: -200px;
        padding: 5px;
        border-radius: 5px;
        background-color: var(--dark-blue);
        transition: 0.2s;
        font-size: 1.8em;
        transition: 0.2s;
    }

    .contact-and-legals .number-frame:hover{
        opacity: 1;
    }

    .contact-and-legals .number{
        z-index: 0;
    }
    .contact-and-legals .number-frame:hover .number.horizontal{
    	opacity: 1;
    	transition: 0.2s;
        top: 7vh;
    }
    .contact-and-legals .number-frame:hover .number.vertical{
    	opacity: 1;
    	transition: 0.2s;
        left: 50px;
    }
}
@media (orientation: portrait) {
    .contact-and-legals *{
        display: none;
    }
}
