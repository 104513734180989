.biguserdata.expanded{
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	background-color: var(--background);
	height: 80vh;
	width: 100vw;
	position: fixed;
	top: 10vh;
	left: 0vw;
	/*transition: position 2s, height 2s, width 2s;*/
	padding-left: 0.5em;
	padding-bottom:1vh;
	z-index: 8;
}

.biguserdata .avatar{
	height: 20vh;
	width:  20vh;
	border-radius: 1000px;
}

.biguserdata .column{
	margin: 1em;
	padding-left: 1em;
	padding-right: 1em;
	padding-top: 1em;
	border-radius: 2px;
	background-color: white;
	max-width: 15vw;
	min-width: 300px;
	justify-content: flex-start;
}

.biguserdata .first{
	flex: 0;
}

.biguserdata .secondary{
	flex: 0;
	padding-top: 0;
}

.biguserdata .third{
	flex: 0;
	background-color: transparent;
	padding-top: -1em;
	margin-top: -1em;
}
.biguserdata .fourth{
	flex: 0;
	background-color: transparent;
	padding-top: -1em;
	margin-top: -1em;
}

.biguserdata .addresses{
	max-width: 100%;
	max-height: 33vh;
	margin-top: 0.5em;
	margin-bottom: 0.5em;
}

.biguserdata hr{
	color: white;
	width: 100%;
}

.biguserdata .topics{
	max-height: 60vh;
	overflow-y: auto;
}

.biguserdata .topics .topic-item{
	cursor: pointer;
	padding: 0.5em;
	margin: 0.5em;
}

.biguserdata .topics .topic-item:hover{
	box-shadow: 0 0 1px 1px rgba(150,150,150, 0.3);
}

.biguserdata .topics .active{
	box-shadow: 0 0 1px 1px rgba(150,150,150, 0.3);
	background-color: #eff;
}


.biguserdata .levels{
	box-shadow:inset 0 0 1px 1px rgba(150,150,150, 0.3);
	padding: 0.5em;
	padding-left: 2em;
	padding-right: 1em;
	text-align: right;
	font-size: 0.9em;
}

.biguserdata #infos{
	max-height: 65vh;
	overflow-y: auto;
	padding: 0;
	margin: 0;
}

.biguserdata .resume-label:first-letter{
	text-transform: capitalize;
}

.biguserdata #accreditations{
	height: 25vh;
	overflow-y: auto;
}

.biguserdata #name-and-number{
	margin: 5px;
	padding: 5px;
}

.biguserdata #name-and-number p{
	margin: 0px;
	padding: 5px;
}

#resume-container{
	padding-top: 0;
}
