.date-time-picker{
	background-color: white;
	border-radius: 10px;
	padding: 0.3em;
	box-shadow: 0px 0px 3px 3px rgba(200,200,200,0.5);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	z-index: 1000;
}

.fixed{
		position: fixed;
		top: 17%;
}

.no-frame{
	box-shadow: none !important;
}

.react-calendar{
	border: none;
	height: 18em;
	width: 19em;
}

.time-picker{
	display: flex;
	flex-direction: row;
	align-items: center;
	margin: 0.8em;
	padding: 0.5em;
	padding-left: 1.5em;
	padding-right: 1.5em;
	font-size: 1.05em;
	background-color: var(--blue-btn);
	color: white;
	border-radius: 50px;
}

.time-picker-select{
	appearance: none;
	border-style: none;
	font-size: 1em;
	background-color: inherit;
	color: inherit;
	padding-left: 0.2em;
	padding-right: 0.2em;
	cursor: pointer;
}

.today-tile{
	color: var(--yellow);
	border-radius: 60px;
}

.chosen-date-tile{
	color: var(--yellow);
	border-radius: 60px;
	background-color: var(--blue);
}
