.home-container{
	margin-top: 0px;
	padding-top: -80px;
	display: flex;
	flex-direction: column;
	align-items: center;
  justify-content: center;
	background-color: var(--dark-blue);
	color: grey;
	min-height: 100vh;
	text-align: center;
}

.home-logo{
	height: 15em;
	width: 15em;
	margin-bottom: -2em;
	margin-top: 4em;
}

#register-logo{
    height: 10em;
    width: 10em;
    margin-bottom: -2em;
    /*margin-top: -8em;*/
}

#homeButton{
	background-color: var(--blue-btn);
	padding: 0.8em;
	color: white;
	font-size: 1.1em;
	margin-top: 1em;
	width: 10em;
	margin-right: auto;
	margin-left: auto;
}

#registerButton{
    background-color: var(--green);
    padding: 0.8em;
    color: white;
    font-size: 1.1em;
    margin-top: 1em;
    width: 8em;
    margin-right: auto;
    margin-left: auto;

}

.home-form{
	padding: 0.5em !important;
	font-size: 1.1em;
	margin-top: 0em;
}

.home-form-container{
	margin-top: -10em;
}

.contact{
	position: absolute;
	top: 0px;
	left: 0px;
}


.login-footer{
	position: fixed;
	bottom: 0px;
	right: 0;
	/*margin: 1px;*/
	display: flex;
	font-size: 2em;
	color: var(--dark-blue);
	width: 100%;
	max-width: 100;
	background-color: rgba(255,255,255, 1);
/*	box-shadow: 0px -10px 5px  rgba(255,255,255, 1);*/

}

.partners-logos{
	display: flex;
	flex: 1;
	flex-direction: column;
}

.partner-logo{
	margin-top: 0;
	margin-bottom: 10px;
	margin-left: 10px;
	height: auto;
	max-height: 3em;
	max-width: 6em;
}


.mobile-app{
	display: none;
}

 @media only screen  and (min-device-width: 360px)

  and (max-device-width: 740px) {
	.mobile-app{
		z-index: 1000;
		display: flex;
		height: 50vh;
		width: 80vw;
		position: fixed;
		top: 10vh;
		left: 10vw - 5px;
		background-color: var(--white);
		border-radius: 10px;
		color: #111;
		font-size: 1.1em;
		justify-content: center;
		flex-direction: column;
		padding: 10px;
	}
}

.stores-badges{
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
}

#play-store{
	max-height: 5em;
}

#app-store{
	height: 3.5em;
}

#subtitle{
	color: var(--white);
	font-size: 1.1em;
}
