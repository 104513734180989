.dashboard-container{
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	height: 100vh;
	overflow: hidden;
	background-color: transparent;
}

.dshbd-bg{
	/*
	background-image: url(../files/images/background.jpg);
	background-size: cover;
  	background-position: center;
  	position: absolute;
  	height: 100vh;
  	width: 100vw;
  	top: 0;
  	left: 0;
  	filter: blur(2px);
  	opacity: 0.3;*/
}

#dshbd-logo{
	height: 20vh;
	width: 20vh;
	margin-top: -10vh;
	margin-bottom: -10vh;
	margin-left: -10vh;
	z-index: 10;

	position: fixed;
	left: 50vw;

	transform: scale(1);

	transition: left 0.5s, height 0.5s, width 0.5s, transform 0.5s cubic-bezier(0,0, 0,-1);

}

#dshbd-logo.shrinker{
	/*margin-top: -10vh;
	margin-bottom: -10vh;
	margin-left: -10vh;
	z-index: 10;*/

	transform: scale(0.75);

	position: fixed;
	left: 50vw;
	top: 6vh;

	transition: left 0.5s, height 0.5s, width 0.5s, transform 0.5s cubic-bezier(0,1, 0,1);
}


.loader{
	position: absolute;
    top: 0px;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 1000;
    /* align-items: center; */
    /* text-align: center; */
    /* justify-content: center; */
    /* justify-items: center; */
    display: flex;
    justify-content: center;
    align-items: center;
}


.dshbd-band{
	display: flex;
	flex-direction: row;
	background-color: var(--dark-blue);
	max-height: 10vh;
	min-height: 10vh;
	max-width:100vw;
	width: 95vw;
	padding-right: 2.5vw;
	padding-left: 2.5vw;
	flex: 1;
	box-shadow: 0px 0px 2px 2px rgba(0,0,0,0.2);
	justify-content: space-around;
	align-items: center;
	z-index: 5;
}

.dshbd-band.head{
	justify-content: space-between;
}
.dshbd-band.foot{
	position: fixed;
	bottom: 0;
	z-index: 20;
}

.logo.foot{
	height: 7vh !important;
	width: 7vh !important;
}

.foot.icon.inactive{
	flex: 0;
	height: 5vh;
	width: 5vh;
	cursor: pointer;
	opacity: 0.6;
}

.foot.icon.active{
	flex: 0;
	height: 5vh;
	width: 5vh;
}

.dshbd-body{
	flex: 1;
	height: 80vh;
	width: 100vw;
	align-items: center;

}

.container{
	display: flex;
	flex-direction: column;
	align-items: center;
}

.orderer-select{
	width: 15em;
}

.move{
	animation: defilement-rtl 15s infinite linear;

}

#orderer-price{
	font-size: 2em;
	font-weight: bold;
	color: var(--yellow);
	margin: 0.1em;
	height: 2em;
}

#orderer-btn{
	padding-left: 2em;
	padding-right: 2em;
}

.inner{
	z-index: 1;
}

.inner.center{
	margin-top: 5vh;
}

.inner.side{
	overflow: hidden;
	transition: transform 0.1s;
	box-shadow: 0px 0px 3px 3px rgba(0,0,0,0.1);
	cursor: pointer;
	z-index: 0;
	min-height: 80vh;
}

.inner .content{
	height: 100%;
}

.inner.side.left{
	transform: translate(-45px) scale(0.75);
}


.inner.side.right{
	transform: translate(45px) scale(0.75);
	background-color: white;
}

.side-title{
	display: flex;
	visibility: hidden;
	opacity: 0;
	transition: opacity 0.2s, visibility 0s 1s;
	position: absolute;
	top: 0%;
	left: 0%;
	color: white;
	font-weight: bold;
	font-size: 2em;
	width: 100%;
	height: 100%;
	align-items: center;
	justify-content: center;
	background-color: rgba(0, 0, 0 ,0.5);
}

.inner.side:hover{
	/*box-shadow: 0px 3px 5px 5px rgba(0,0,0,0.1);/*var(--blue);*/

}

.inner.side.left:hover{
	transform: translate(-45px) scale(0.75);
	transition: transform 0.02s;
}

.inner.side.right:hover{
	transform: translate(45px) scale(0.75);
	transition: transform 0.02s;
}

.inner.side .content{
	filter: none;
}


.inner.side:hover .content{
	filter: blur(1px);
	transition: filter 0.2s;
}


.inner.side.right:hover .side-title.right{
	visibility: visible;
	opacity: 1;
	transition: opacity 0.2s;
}

.inner.side.left:hover .side-title.left{
	visibility: visible;
	opacity: 1;
	transition: opacity 0.2s;
}

#logout{
	display: flex;
	flex-direction: row-reverse;
}
#visio-shortcut{
	position: fixed;
	right: 20vw;
	top: 1.6vh;
}

.tooltip{
	opacity: 0;
	color: var(--white);
	margin: 0;
	transition: 0.2s;
	margin-top: 5px;
	margin-right: 5px;
}

#pass-status-btn{
	margin-right: 10vw;
}
/*
#pass-status-btn:hover{
	opacity: 1;
	cursor: default;
}
*/
