form{
	display: flex;
	flex-direction: column;
}

.form-control{
	border-radius: 50px;
	float: left;
	border-style: none;
	padding: 0.5em;
	margin: 0.3em;
	text-align: center;
	background-color: #fafafa;
}

.form-control.invalid{
	box-shadow: 0px 0px 3px 2px red;
}

.form-control.valid{
	box-shadow: 0px 0px 3px 2px var(--green);
}

.form-btn{
	padding: 0.5em;
	margin: 0.5em;
}

.half-btn{
	background-color: white;
}

.half-btn.valid{
	box-shadow: 0px 0px 3px 2px var(--green);
}

.half-btn.invalid{
	box-shadow: 0px 0px 3px 2px red;
}

.half-btn.left{
	/*width: 49%;*/
	border-top-right-radius: 0px !important;
	border-bottom-right-radius: 0px !important;
	padding: 0.5em !important;
}

.half-btn.right{
	/*width: 46%;*/
	border-top-left-radius: 0px !important;
	border-bottom-left-radius: 0px !important;
	padding: 0.5em !important;
	overflow: hidden;

	/*position: relative !important;
	top: -2.76em;
	left: 10.5em;*/

}

.showPwd{
	float: right;
	margin-top: -2.7em;
	margin-right: 0.5em;
	margin-left: auto;
	margin-bottom: 0.5em;
	order: 0;

	border-radius: 50px;
	width: 2em;
	height: 2em;


	border-style: none;
	padding: 0.5em;
}

.showPwd.active{
	background-color: var(--yellow);
}

.text-input{
	border-radius: 50px;
	appearance: none;
	border: none;
	padding: 0.5em;
	margin: 0.3em;
	text-align: center;
}

.oneline{
	height: 2em;
	flex: 0 0;
}

.select{
	align-items: center;
	align-content: center;
	min-height: 2.2em;
	padding: 0em !important;
	width: 100%;
	margin: 0.3em !important;

}


#register-warning{
	max-width: 20em;
	background-color: white;
	padding: 0.5em;
	border-radius: 5px;
}
