.teacher-resume .avatar{
    border-radius: 3px;
    object-fit: cover;
    max-height: 20vh;
}

.teacher-resume .icon{
    font-size: 0.5em;
}

.teacher-resume{
    max-height: 40vh;
    height: 40vh;
    width: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
}

.teacher-resume .resume-label:first-letter{
	text-transform: capitalize;
}
